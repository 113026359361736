import SA from 'scroll-animations-js';
import $ from "jquery";
//import AOS from 'aos';
//import {gsap} from "gsap";
//import {ScrollTrigger} from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
import "owl.carousel";
import axios from "axios";

const locomotiveScroll = new LocomotiveScroll();

const owl = jQuery('.owl-carousel').owlCarousel({
    nav:false,
    dots:true,
    items:1,
    loop:true,
    autoplay:true,
    autoplayTimeout:7000,
    autoplayHoverPause:true
});

owl.on('initialize.owl.carousel', () => {

})

SA.init({});

// Função para adicionar ou remover a classe 'top' no body
window.onscroll = function () {
    var body = document.body;

    // Se o usuário estiver no topo da página (scrollY == 0), adicionar a classe 'top'
    if (window.scrollY === 0) {
        body.classList.add('top');
    } else {
        body.classList.remove('top');
    }
};

// Ao carregar a página, verifica se está no topo e adiciona a classe 'top' se for o caso
window.onload = function () {
    if (window.scrollY === 0) {
        document.body.classList.add('top');
    }
};

let textLengthArray = [];

function type(index) {
    let paragraph = document.getElementsByClassName("typed")[index];
    let text = paragraph.getAttribute("data-text");
    let textLength = textLengthArray[index];

    if (!textLength) {
        textLength = 0;
        textLengthArray[index] = 0;
        paragraph.innerText = "";
    }

    let textChar = text.charAt(textLength++);
    let charElement = document.createTextNode(textChar);
    paragraph.appendChild(charElement);

    if (textLength < text.length + 1) {
        textLengthArray[index] = textLength;
        setTimeout(() => {
            type(index);
        }, 50);
    }
}

function countUp(index) {
    let counter = document.getElementsByClassName("counter")[index];
    let targetValue = parseFloat(counter.getAttribute("data-target")); // Pode ser float ou int
    let integerPart = Math.floor(targetValue); // Parte inteira
    let decimalPart = targetValue % 1; // Parte decimal
    let currentValue = 0; // Valor inicial
    let increment = 1; // Incremento para a parte inteira

    function countIntegers() {
        if (currentValue < integerPart) {
            currentValue += increment;
            counter.innerText = currentValue; // Atualiza o contador com o valor inteiro
            setTimeout(countIntegers, 50); // Atualiza a cada 50ms
        } else {
            currentValue = integerPart; // Garante o valor final
            counter.innerText = currentValue; // Exibe o valor inteiro final
            if (decimalPart > 0) {
                setTimeout(countDecimals, 50); // Inicia a contagem decimal
            }
        }
    }

    function countDecimals() {
        let decimalCurrent = 0; // Começa do 0 para os decimais
        let decimalIncrement = decimalPart / 10; // Incrementa em etapas menores (0.1, 0.2, ...)

        function updateDecimals() {
            if (decimalCurrent < decimalPart) {
                decimalCurrent += decimalIncrement;

                // Evita ultrapassar o valor decimal
                if (decimalCurrent > decimalPart) {
                    decimalCurrent = decimalPart;
                }

                counter.innerText = `${integerPart}${decimalCurrent.toFixed(1).substring(1)}`; // Atualiza ex: "5.1"
                setTimeout(updateDecimals, 50); // Atualiza a cada 50ms
            } else {
                counter.innerText = targetValue.toFixed(1); // Garante o valor final exato
            }
        }

        updateDecimals();
    }

    countIntegers();
}

function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function checkScroll() {
    let paragraphs = document.querySelectorAll(".typed");
    paragraphs.forEach((paragraph, index) => {
        if (isElementInViewport(paragraph) && !paragraph.classList.contains('started')) {
            paragraph.classList.add('started');
            type(index); // Inicia a animação de digitação
        }
    });

    let counters = document.querySelectorAll(".counter");
    counters.forEach((counter, index) => {
        if (isElementInViewport(counter) && !counter.classList.contains('counted')) {
            counter.classList.add('counted');
            setTimeout(() => {
                countUp(index); // Inicia o count up após um delay
            }, 1000 * index); // Aplica o delay de 300ms por cada índice
        }
    });
}

document.addEventListener("DOMContentLoaded", function () {
    let paragraphs = document.querySelectorAll(".typed");
    paragraphs.forEach((paragraph, index) => {
        let text = paragraph.innerText;
        paragraph.setAttribute("data-text", text);
    });

    let counters = document.querySelectorAll(".counter");
    counters.forEach(counter => {
        counter.innerText = "0"; // Inicializa os contadores com zero
    });

    window.addEventListener("scroll", checkScroll);
    checkScroll(); // Checa a visibilidade inicial no carregamento da página
});


$(".tabs a").click(function () {
    $("a", $(this).parent()).removeClass("act");
    $(this).addClass("act");
    $('.servico[data-title="' + $(this).attr("data-click-title") + '"]').hide();
    $('.servico[data-id="' + $(this).attr("data-click") + '"][data-title="' + $(this).attr("data-click-title") + '"]').show();
    return false;
});


$(".image-video").click(function () {
    $(this).html(decodeURIComponent($(".image-video").attr("data-embed")));
});


$(document).resize(function () {

})


function effectsLeft() {
    $(".max-1198");
    $(".max-left-1198");
}

document.addEventListener('DOMContentLoaded', function() {
    const parallaxAssets = document.querySelectorAll('.parallax-asset img');

    // Função para aplicar o efeito parallax a um elemento específico
    function applyParallaxEffect(scrollPosition, element) {
        element.style.transform = 'translateY(' + (scrollPosition * 0.1) + 'px)';
    }

    // Configuração do IntersectionObserver
    const observerOptions = {
        root: null, // Usa o viewport como referência
        threshold: 0.1 // Aciona quando 10% do elemento está em view
    };

    parallaxAssets.forEach(parallaxAsset => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Adiciona o listener de scroll apenas quando o elemento está visível
                    window.addEventListener('scroll', function onScroll() {
                        const scrollPosition = window.scrollY;
                        applyParallaxEffect(scrollPosition, parallaxAsset);
                    });
                }
            });
        }, observerOptions);

        // Observa cada elemento
        observer.observe(parallaxAsset);
    });
});

jQuery(".menu-mobile").click(function () {
    jQuery(this).toggleClass("act");
    jQuery("header .menu-container").toggleClass("act");
    return false;
})

setTimeout(function () {
    jQuery('#quick-message').slideUp(300, function(){
        jQuery(this).remove();
    });
}, 9000);

document.addEventListener('DOMContentLoaded', function() {
    // Seleciona todos os formulários com a classe "submit"
    const forms = document.querySelectorAll('form.submit');

    forms.forEach(form => {
        form.addEventListener('submit', function(event) {
            event.preventDefault(); // Impede o envio padrão do formulário

            const formData = new FormData(form); // Cria o FormData a partir do formulário
            const data = {};

            // Converte o FormData para um objeto JavaScript usando o atributo 'name' como chave
            formData.forEach((value, key) => {
                data[key] = value;
            });

            // Estrutura de dados para envio
            const submissionData = {
                to: form.getAttribute("data-to"),
                url: form.getAttribute("data-url"),
                form_name: form.getAttribute("name"),
                form_type: "Contato", // Tipo de serviço
                submission_date: new Date().toISOString(), // Data atual no formato 'YYYY-MM-DD'
                submission_data: JSON.stringify(data), // Converte todos os dados do formulário para JSON
            };

            form.classList.add("loading");
            // Envia os dados com Axios
            axios.post(site_url.url + '/wp-json/legatus/v1/submit-form', submissionData)
                .then(response => {
                    alert(form.getAttribute("data-resposta") ? form.getAttribute("data-resposta") : 'Formulário enviado com sucesso!');
                    console.log(response.data);
                    form.reset(); // Limpa o formulário após o envio
                    form.classList.remove("loading");
                })
                .catch(error => {
                    alert('Ocorreu um erro ao enviar o formulário.');
                    console.error(error);
                    form.classList.remove("loading");
                });
        });
    });
});
